
import React, { useState, useEffect, useRef } from 'react'
import { MessageContainer } from 'components/Message/Message'
import { ChatBox } from 'components/ChatBox/ChatBox'
import { getUser } from 'hooks/getUser'
import { llmEndpoint, rpaId } from 'infra'


export const ChatContainer = () => {
  const [messages, setMessages] = useState([])
  const [chats, setChats] = useState([])
  const [inputMessage, setInputMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [openPopupId, setOpenPopupId] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ isOpen: false, x: 0, y: 0 });
  const [loadingDots, setLoadingDots] = useState('.')
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [selectedChatId, setSelectedChatId] = useState(null);

  const [editingChatId, setEditingChatId] = useState(null);
  const [editingChatName, setEditingChatName] = useState('');

  const [isNewChatModalOpen, setIsNewChatModalOpen] = useState(false);
  const [newChatName, setNewChatName] = useState('');
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [chatToDelete, setChatToDelete] = useState(null);

  const messagesEndRef = useRef(null)

  const confirmDeleteChat = async () => {
    if (chatToDelete) {
      try {
        const response = await fetch(`${llmEndpoint.url}/folder/${chatToDelete}`, {
          method: 'DELETE',
        });
  
        if (!response.ok) {
          throw new Error('Erro ao deletar chat');
        }
  
        setChats(chats.filter(chat => chat.id !== chatToDelete));
        if (selectedChatId === chatToDelete) {
          setSelectedChatId(null);
          setMessages([]);
        }
      } catch (error) {
        console.error('Erro ao deletar chat:', error);
      }
      setIsDeleteConfirmationOpen(false);
      setChatToDelete(null);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  const handleDeleteChat = (chatId) => {
    setChatToDelete(chatId);
    setIsDeleteConfirmationOpen(true);
    setPopupPosition(prev => ({ ...prev, isOpen: false }));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleNewChat = () => {
    setIsNewChatModalOpen(true);
  }

  const handleSaveNewChat = async () => {
    if (newChatName.trim() !== '') {
      const userId = getUser().id
      const companyId = getUser().companies[0].id;
      console.log('Buscando dados da user:', userId);
      try {
        const response = await fetch(`${llmEndpoint.url}/folder`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            name: newChatName,
            userId: userId, 
            companyId: companyId,
            rpaId: rpaId.value,
          }),
        });

        if (!response.ok) {
          throw new Error('Erro ao criar novo chat');
        }

        const newChat = await response.json();
        setChats([...chats, newChat]);
        setNewChatName('');
        setIsNewChatModalOpen(false);
        // Opcionalmente, você pode querer selecionar o novo chat automaticamente
        handleChatBoxClick(newChat.id);
      } catch (error) {
        console.error('Erro ao criar novo chat:', error);
      }
    }
  }

  useEffect(() => {
    if (messages.length > 0) {
        scrollToBottom()
    }
  }, [messages])


  const fetchChats = async () => {
    try {
      const userId = getUser().id
      console.log('Buscando dados da user:', userId);
      const response = await fetch(
        `${llmEndpoint.url}/folder/user/${userId}?rpaId=${rpaId.value}`,
      )
      if (!response.ok) {
        throw new Error('Erro ao buscar chats');
      }
      const data = await response.json();
      setChats(data);
    } catch (error) {
      console.error('Erro ao buscar chats:', error);
    }
  };

  useEffect(() => {
    fetchChats();
  }, []);


  const fetchMessages = async (chatId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${llmEndpoint.url}/chat?folderId=${chatId}`)
      if (!response.ok) {
        throw new Error('Erro ao buscar mensagens');
      }
      const data = await response.json();
      setMessages(data);
      setSelectedChatId(chatId);
    } catch (error) {
      console.error('Erro ao buscar mensagens:', error);
    }
    setIsLoading(false);
  };

  const handleChatBoxClick = (chatId) => {
    fetchMessages(chatId);
  };

  useEffect(() => {
    let interval
    if (isLoading) {
      interval = setInterval(() => {
        setLoadingDots(dots => dots.length >= 3 ? '.' : dots + '.')
      }, 500)
    }
    return () => clearInterval(interval)
  }, [isLoading])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (popupPosition.isOpen && !event.target.closest('.popup-menu')) {
        setPopupPosition(prev => ({ ...prev, isOpen: false }));
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [popupPosition.isOpen]);

  const handleSendMessage = async () => {
    if (inputMessage.trim() !== '') {
      setIsLoading(true)
      setMessages([...messages, { message: inputMessage, variant: 'right' }])

      try {
        const response = await fetch(
          `${llmEndpoint.url}/chat?folderId=${selectedChatId}`,
          {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ message: inputMessage, isBot: false }),
        })

        if (!response.ok) {
          throw new Error('Erro na resposta da API')
        }

        const data = await response.json()

        setMessages(prevMessages => [...prevMessages, { message: data.response, isBot: true }])
      } catch (error) {
        console.error('Erro ao enviar mensagem:', error)
      }
      setIsLoading(false)
      setInputMessage('')
    }
  }

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage()
    }
  }

  const handleOptionsClick = (e, chatId) => {
    e.stopPropagation();
    setOpenPopupId(chatId);
    setPopupPosition({
      isOpen: true,
      x: e.clientX,
      y: e.clientY
    });
  };

  const handleSaveRename = async () => {
    if (editingChatName.trim() !== '') {
      try {
        const response = await fetch(
          `${llmEndpoint.url}/folder/name/${editingChatId}?newName=${editingChatName}`,
          {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ name: editingChatName }),
        });

        if (!response.ok) {
          throw new Error('Erro ao renomear chat');
        }

        setChats(chats.map(chat => 
          chat.id === editingChatId ? { ...chat, name: editingChatName } : chat
        ));
        setEditingChatId(null);
        setEditingChatName('');
      } catch (error) {
        console.error('Erro ao renomear chat:', error);
      }
    }
  };

  const handleRenameChat = (chatId, currentName) => {
    setEditingChatId(chatId);
    setEditingChatName(currentName);
    setPopupPosition(prev => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <div className="grid grid-cols-12 h-screen antialiased text-gray-800 bg-grayf9">
      <div className={`${isSidebarOpen ? 'col-span-2' : 'col-span-1'} flex flex-col py-8 pl-6 pr-2 bg-white overflow-y-auto transition-all duration-300`}>
      <div className="flex flex-row items-center justify-between px-4 h-12 w-full">
    <div className="flex items-center">
      <div className="flex items-center justify-center rounded-2xl text-indigo-700 bg-indigo-100 h-10 w-10">
        <svg
          className="w-6 h-6"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
          ></path>
        </svg>
      </div>
      {/* {isSidebarOpen && <span className="ml-2 text-lg font-semibold">Chat</span>} */}
    </div>
    <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-800">
      {isSidebarOpen ? (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 19l-7-7 7-7m8 14l-7-7 7-7" />
        </svg>
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 5l7 7-7 7M5 5l7 7-7 7" />
        </svg>
      )}
    </button>
  </div>

        {isSidebarOpen && (
        <>
           <div className="flex flex-col mt-8 flex-grow">
          <div className="flex flex-row items-center justify-between text-xs">
            <span className="font-bold">Últimos Chats</span>
            
            <button
              onClick={handleNewChat}
              className="bg-indigo-500 hover:bg-indigo-600 text-white font-bold py-2 px-4 rounded"
            >
              Novo Chat
            </button>
          </div>
          <div className="flex flex-col space-y-1 mt-4 -mx-2 overflow-y-auto flex-grow">
          {chats.map((chat) => (
              <div key={chat.id}>
                {editingChatId === chat.id ? (
                  <div className="flex items-center p-2">
                    <input
                      type="text"
                      value={editingChatName}
                      onChange={(e) => setEditingChatName(e.target.value)}
                      className="flex-grow border rounded px-2 py-1 mr-2"
                    />
                    <button onClick={handleSaveRename} className="text-green-500 mr-2">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                      </svg>
                    </button>
                    <button onClick={() => setEditingChatId(null)} className="text-red-500">
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
                      </svg>
                    </button>
                  </div>
                ) : (
                  <ChatBox 
                    key={chat.id} 
                    value={chat.name} 
                    onClick={() => handleChatBoxClick(chat.id)}
                    onOptionsClick={(e) => handleOptionsClick(e, chat.id)}
                    isSelected={chat.id === selectedChatId}
                  />
                )}
              </div>
            ))}
          </div>
        </div>  
        </>
        )}
     
       
        </div>
        <div className={`${isSidebarOpen ? 'col-span-10' : 'col-span-11'} flex flex-col transition-all duration-300`}>
        <div className="flex-grow overflow-y-auto">
          <div className="flex flex-col h-full w-full p-4">
            <div className="grid grid-cols-12 gap-y-2 overflow-y-auto">
            {selectedChatId ? (
                  messages.map((message, index) => (
                    <MessageContainer key={index} variant={message.isBot ? 'left' : 'right'} value={message.message} />
                  ))
                ) : (
                  <div className="col-span-12 text-center py-10">
                    <p className="text-xl font-semibold mb-4">Bem-vindo ao Chat</p>
                    <p>Selecione um chat existente ou crie um novo para começar.</p>
                  </div>
                )}
              <div ref={messagesEndRef} /> 
            </div>
          </div>
        </div>
        <div className="bg-gray-100 p-4 w-full sticky bottom-0 border-t border-gray-200">
          <div className="flex flex-row items-center h-16 rounded-xl bg-white w-full px-4">
            <div className="flex-grow">
              <div className="relative w-full">
                <input
                  type="text"
                  className="flex w-full border rounded-xl focus:outline-none focus:border-indigo-300 pl-4 h-10"
                  placeholder="Digite sua mensagem..."
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  onKeyPress={handleKeyPress}
                  disabled={!selectedChatId}
                />
              </div>
            </div>
            <div className="ml-4">
              <button 
                className="flex items-center justify-center bg-indigo-500 hover:bg-indigo-600 rounded-xl text-white px-4 py-1 flex-shrink-0"
                onClick={handleSendMessage}
                disabled={isLoading}
              >
                <span>{isLoading ? loadingDots : 'Enviar'}</span>
                {!isLoading && (
                  <span className="ml-2">
                    <svg
                      className="w-4 h-4 transform rotate-45 -mt-px"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                      ></path>
                    </svg>
                  </span>
                )}
              </button>
            </div>
          </div>
        </div>
        </div>
      </div>
      {popupPosition.isOpen && (
        <div 
          className="fixed bg-white rounded-md overflow-hidden shadow-xl z-10 popup-menu"
          style={{
            top: `${popupPosition.y}px`,
            left: `${popupPosition.x}px`,
            transform: 'translate(-100%, 0)'
          }}
        >
          <div
            className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleRenameChat(openPopupId, chats.find(chat => chat.id === openPopupId)?.name)}
          >
            Renomear
          </div>
          <div
            className="px-4 py-2 text-sm text-red-600 hover:bg-gray-100 cursor-pointer"
            onClick={() => handleDeleteChat(openPopupId)}
          >
            Deletar
          </div>
        </div>
      )}
        {isNewChatModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
          <div className="bg-white p-5 rounded-lg shadow-xl w-96">
            <h2 className="text-xl mb-4">Novo Chat</h2>
            <input
              type="text"
              value={newChatName}
              onChange={(e) => setNewChatName(e.target.value)}
              placeholder="Nome do chat"
              className="border p-2 mb-4 w-full"
            />
            <div className="flex justify-end">
              <button
                onClick={() => setIsNewChatModalOpen(false)}
                className="mr-2 px-4 py-2 bg-gray-300 rounded"
              >
                Cancelar
              </button>
              <button
                onClick={handleSaveNewChat}
                className="px-4 py-2 bg-indigo-500 text-white rounded"
              >
                Salvar
              </button>
            </div>
          </div>
        </div>
      )}
      {isDeleteConfirmationOpen && (
      <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex items-center justify-center">
        <div className="bg-white p-5 rounded-lg shadow-xl w-96">
          <h2 className="text-xl mb-4">Confirmar Exclusão</h2>
          <p className="mb-4">Tem certeza que deseja deletar este chat?</p>
          <div className="flex justify-end">
            <button
              onClick={() => setIsDeleteConfirmationOpen(false)}
              className="mr-2 px-4 py-2 bg-gray-300 rounded"
            >
              Cancelar
            </button>
            <button
              onClick={confirmDeleteChat}
              className="px-4 py-2 bg-red-500 text-white rounded"
            >
              Deletar
            </button>
          </div>
        </div>
      </div>
    )}
    </>
  )
}
