export const endpointsUploader = {
  getRpaActionEndpoint: "rpa/actions",
  getRpaActionNewSubscriptionEndpoint: "rpa/actions/form",
  getRpaConfigByRpaId: "rpa/scheduler/rpa-config/:rpaId",
  getRpaFlow: "rpa/flows",
  getModelCSV: "rpa/csv/model",
  postCSV: "rpa/csv",
  postCleanAll: "rpa/actions/clean-all",
  postCleanById: "rpa/actions/clean",
  postClean: "rpa/actions/clean",
  postDownloadActionAll: "rpa/actions/download-all",
  postDownloadAction: "rpa/actions/download",
  postDownloadFlowAll: "rpa/flows/download-all",
  postDownloadFlow: "rpa/flows/download",
} as const;

export const endpointsPagarME = {
  getRecipients: "/v1/pagar-me/recipients",
  getPlans: "/v1/pagar-me/plans",
  postTestCredential: "/v1/pagar-me/credential",
} as const;

export const endpointsFlow = {
  postInit: "rpa/flow/init",
} as const;

const extractIdFromUrl = (url: string) => {
  const parts = url.split("/");
  return parts[parts.length - 1];
};

export const rpaId = {
  value: extractIdFromUrl(window.location.href),
} as const;

// export const llmEndpoint = {
//   url: "http://localhost:8080/api/v1",
// } as const;

export const llmEndpoint = {
  url: "https://portico-apolo.intraversa.com/llm/api/v1",
} as const;
