import { useCallback, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import {
  BreadcrumbItem,
  Breadcrumbs
} from 'components/Breadcrumbs/Breadcrumbs';
import { CardChooseConfig } from 'components/CardChooseConfig';
import axios from 'axios';
import { CardIntegration } from 'components/ContainerCollapse/ContainerCollapse';
import {
  ENUM_TYPES_SCHEDULER_CONFIG,
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes
} from 'components/ContainerCollapse/meta/schedulerConfigTypes';
import {
  IRpaConfigResponse,
  Scheduler
} from 'domains/RpaConfig/rpaConfigTypes';
import { useGetConfigRpa } from 'domains/RpaConfig/useCases';
import { useParams } from 'react-router-dom';
import { getUser } from 'hooks/getUser';
import { llmEndpoint } from 'infra';

export const Configuracoes = () => {
  const { rpaId } = useParams();

  const { data: rpaConfig } = useGetConfigRpa({
    rpaId,
    enabled: true
  });

  const [selectedConfig, setSelectedConfig] = useState<ENUM_TYPES_SCHEDULER_CONFIG_VALUES | null>(null);
  const [configData, setConfigData] = useState<any>(null);
  const [breadcrumbs, setBreadcrumbs] = useState<BreadcrumbItem[]>([]);
  const [llmScript, setLlmScript] = useState<string>('');

  const fetchConfigData = useCallback(async (type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES) => {
 
    setConfigData({
      id: '123',
      name: type,
      credential: {},
      scheduler: type === ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER ? {} : null
    });
  }, []);

  useEffect(() => {
    const companyId = getUser().companies[0].id;
    const fetchLlmScript = async () => {
      try {
        const response = await axios.get(
          `${llmEndpoint.url}/company-llm/${companyId}?rpaId=${rpaId}`,
          {
          headers: {
            'accept': '*/*'
          }
        });
        setLlmScript(response.data.initialScript); // Assumindo que o script está em response.data.script
      } catch (error) {
        console.error('Erro ao buscar o script LLM:', error);
      }
    };

    fetchLlmScript();
  }, []);

  const handleSelectCard = useCallback((type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES) => {
    setSelectedConfig(type);
    fetchConfigData(type);

    const informationsConfig = schedulerConfigTypes.find(config => config.type === type);
    setBreadcrumbs([
      { name: 'Configurações', value: 'config' },
      { name: informationsConfig.title, value: type }
    ]);
  }, [fetchConfigData]);

  const handleChangeBreadcrumbs = useCallback((value: string) => {
    if (value === 'config') {
      setBreadcrumbs([]);
      setSelectedConfig(null);
      setConfigData(null);
    }
  }, []);

  useEffect(() => {
    if (rpaConfig && !selectedConfig) {
      const defaultConfig = rpaConfig.config.scheduler 
        ? ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER 
        : rpaConfig.config.outbounds[0]?.name as ENUM_TYPES_SCHEDULER_CONFIG_VALUES;
      handleSelectCard(defaultConfig);
    }
  }, [rpaConfig, selectedConfig, handleSelectCard]);
  return (
    <div className="pb-6 pl-6 pr-6">

      {!selectedConfig && (
        <>
           <Stack spacing={'16px'} mb={'16px'}>
              <CardChooseConfig
                key={'LLM_SCRIPT'}
                type={ENUM_TYPES_SCHEDULER_CONFIG.LLM_SCRIPT}
                onSelected={handleSelectCard}
              />
            </Stack>
        </>
      )}
     

      {selectedConfig && (
        <>
          <Breadcrumbs
            items={breadcrumbs}
            active={selectedConfig}
            onChange={handleChangeBreadcrumbs}
          />

          {configData && (
            <CardIntegration
              key={configData.id}
              type={selectedConfig}
              script={llmScript}
              scheduler={configData.scheduler}
              configId={configData.id}
            />
          )}
        </>
      )}
    </div>
  );
};
