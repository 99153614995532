'use client'

import { BotMessageSquare, User } from 'lucide-react'
import React, { ReactElement } from 'react'

interface MessageContainerProps {
  variant?: 'left' | 'right'
  value?: string
}

export const MessageContainer: React.FC<MessageContainerProps> = ({
  variant = 'left',
  value,
}) => {
  if (variant === 'left') {
    return (
        <div className="col-start-1 col-end-8 p-3 rounded-lg">
        <div className="flex flex-row items-center">
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
            <BotMessageSquare  color="white"/>
          </div>
          <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
            <pre className="whitespace-pre-wrap font-sans text-sm">
              {value}
            </pre>
          </div>
        </div>
      </div>
    )
  }

  if (variant === 'right') {
    return (
        <div className="col-start-6 col-end-13 p-3 rounded-lg">
        <div className="flex items-center justify-start flex-row-reverse">
          <div className="flex items-center justify-center h-10 w-10 rounded-full bg-indigo-500 flex-shrink-0">
          <User color="white"/>
          </div>
          <div className="relative mr-3 text-sm bg-indigo-100 py-2 px-4 shadow rounded-xl">
            <pre className="whitespace-pre-wrap font-sans text-sm">
              {value}
            </pre>
          </div>
        </div>
      </div>
    )
  }
}
