/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';

import { TextField, ButtonPortico, If } from '@intraversa-lab/styleguide';
import { IconEdit, IconReload } from 'components/Icons/Icons';
import { usePostTestConnection } from 'domains/PagarMe/useCases/usePostTestConnection';
import { IRpaConfigResponse } from 'domains/RpaConfig/rpaConfigTypes';

import {
  IModalConnectivityType,
  ModalConnectivity
} from './components/ModalConnectivity';
import { ModalEdit, ModalEditScript, ModalSchedulerEdit } from './components/ModalEdit';
import {
  ENUM_TYPES_SCHEDULER_CONFIG,
  ENUM_TYPES_SCHEDULER_CONFIG_VALUES,
  schedulerConfigTypes
} from './meta/schedulerConfigTypes';
import * as S from './styles';

export interface CardIntegrationProps {
  type: ENUM_TYPES_SCHEDULER_CONFIG_VALUES;
  script?: string;
  docs?: [];
  scheduler?: IRpaConfigResponse['config']['scheduler'];
  configId: string;
}

export const CardIntegration: React.FC<CardIntegrationProps> = ({
  type,
  script,
  docs,
  scheduler
}) => {
  const isEmail = type === 'EMAIL';
  const [isEdit, setIsEdit] = useState(false);

  const configSelected = schedulerConfigTypes.find(
    config => config.type === type
  );

  return (
    <S.CollapseContainer>
      <S.Wrapper>
        <S.CollapseHeader>
          <div>
            <S.TextLg>{configSelected?.title}</S.TextLg>
            <S.TextDescription>
              Ultima edição 20/01/2025 09:34:01
            </S.TextDescription>
          </div>

          <S.WrapperButtons>
            <div className="middle-button">
              <ButtonPortico
                startIcon={<IconEdit />}
                variant="outlined"
                onClick={() => setIsEdit(!isEdit)}
              >
                Editar
              </ButtonPortico>
            </div>
          </S.WrapperButtons>
        </S.CollapseHeader>
        {type === ENUM_TYPES_SCHEDULER_CONFIG.SCHEDULER && (
          <S.CollapseContent>
            <If condition={!isEmail}>
              {configSelected?.fields.map(item => (
                <S.WrapperInput key={item.name}>
                  <S.TextLg className="labelInput">{item.label}</S.TextLg>
                  <TextField
                    sx={{ paddingLeft: '24px !important' }}
                    type={item.typeInput}
                    showIconPassword={false}
                    defaultValue={scheduler?.[item.name]}
                    disabled
                  />
                </S.WrapperInput>
              ))}
            </If>
          </S.CollapseContent>
        )}
      </S.Wrapper>

      {type === ENUM_TYPES_SCHEDULER_CONFIG.LLM_SCRIPT && (
        <ModalEditScript onClose={() => setIsEdit(false)} isOpen={isEdit} initialScript={script} />
      )}
      {type === ENUM_TYPES_SCHEDULER_CONFIG.LLM_DOCS && (
        <ModalEditScript onClose={() => setIsEdit(false)} isOpen={isEdit} initialScript={script} />
      )}
    </S.CollapseContainer>
  );
};
